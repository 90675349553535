

.linkStyle {
  font-size: 1.25rem;
}


p {
  font-size: 1.25rem;
  color: 'red';
  text-align: left;
  margin: 10 10 10 200;
}

.questionDiv {
  height: 50vh;
  width: 100vw;
  margin: 30px;
  border-style: solid;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

